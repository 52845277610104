.temp-home-image {
	display: block;
}
.temp-home-image {
	display: block;
	width: 100%;
	max-width: 1280px;
	margin: 0 auto;
	height: 459px;
}

.canvas-holder {
	width: 100%;
	position: absolute;
	height: 459px;
}

#canvas {
	display: block;
	position: absolute;
	left: 50%;
	width: 1280px;
	height: 459px;
	cursor: crosshair;
	margin-left: -640px;
}

@media only screen and (max-width: 600px) {
	.homeToy {
		display: none;
	}
}