@import url('https://fonts.googleapis.com/css2?family=Lora&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@300;400;500;600;700&display=swap');

html, body {
  font-family: 'Raleway', sans-serif;
}


.App {
  margin-top: 78px;
  display: flex;
  flex-direction: column;

  img.tempimg {
    width: 100%;
    max-width: 1200px;
    margin: 40px auto 80px;
    position: absolute;
    top: 81px;
    left: 50%;
    margin-left: -600px;
    opacity: 0.2;
    display: none;
  }

  .container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 30px;

    &.two-col {
      display: flex;

      > .left-col {
        margin-right: 15px;
        flex: 1;
      }

      > .right-col {
        margin-left: 15px;
        width: 33%;
      }
    }
  }
}

.headshot {
  padding: 30px 0;

  img {
    width: 65%;
    border: 1px solid #fff;
    border-radius: 8px;
    box-shadow: 9px -9px 0px 0px rgba(0,0,0,.15);
  }
  
}

.App-logo {
  
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #aaa;
  padding: 10px 0;
  position: fixed;
  top: 0;
  width: 100%;
  background: #f9f9f9;
  z-index: 100;


  .container {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    width: calc(100% - 40px);
    max-width: 1200px;
    padding: 0 30px;
    margin: 0 auto;
  }

  h1 {
    font-weight: 600;
    display: block;
    padding: 0;
    margin: 0;

    + span {
      font-family: 'Lora', serif;
      font-weight: 400;
      color: #333;
    }
  }

  .Navigation {
    display: inline-block;

    ul, li {
      margin: 0;
      padding: 0;
    }

    li {
      display: inline-block;

      + li {
        margin-left: 20px;
      }

      a {
        text-decoration: none;
        color: #555;
      }

      &.active a {
        font-weight: 700;
      }
    }
  }

}

p {
  font-size: 15px;
  line-height: 1.5em;
}

h2 {
  font-weight: 500;
  font-size: 21px;
  margin-top: 30px;
}

.App-link {
  color: #61dafb;
}

footer {
  box-shadow: -2px 0 2px 0 rgba(0,0,0,.25);
  padding: 30px 0;
  flex: 0 1;

  .container {
    display: flex;
    justify-content: flex-end;
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@media only screen and (max-width: 600px) {
  .App {
    margin-top: 0;
  }

  .App-header .container {
    flex-direction: column;
    padding: 0 10px;
    width: calc(100% - 20px);
    align-items: flex-start;
  }

  .App-header .Navigation {
    border-top: 1px solid #ccc;
    margin-top: 15px;
    width: 100%;
    padding-top: 10px;

    > ul {
      display: flex;
      justify-content: space-between;

      > li {
        flex: 1;
        text-align: center;

        + li {
          margin-left: 0;
        }
      }
    }
  }

  .App-header {
    position: relative;

  }

  .temp-home-image {
    height: auto;
  }

  .App .container.two-col {
    flex-direction: column;
    
    > .left-col {
      margin: 0;
      order: 2;
    }

    > .right-col {
      margin-left: 0;
      width: 95%;
      order: 1;

      > .headshot {
        padding-bottom: 0;
      }
    }

  }
}

