

@mixin pulse-dot($x: 2px, $y: 2px) {
  content: "";
  display: block;
  position: relative;
  width: 12px;
  height: 12px;
  background: #555;
  left: $x + 5;
  top: $y + 5;
  border-radius: 8px;
  pointer-events: none;
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 1);
  transform: scale(1.2);
  animation: pulse-black 2s infinite;
}


.Timeline {
	display: block;
	width: 1200px;
	height: 4370px;
	position: relative;
    top: 0;
    left: 0;
    margin: 0 auto;
    z-index: 10;
    flex: none;

    .animated {
    	position: absolute;
    	top: 0;
    	left: 0;
    	width: 100px;
    	height: 100px;
    }

    h2 {
        font-size: 28px;
    }

    .cyancdesign {
        font-family: 'Lora', serif;
        font-weight: 600;
        color: #777;
        font-size: 20px;
    }

    .tooltip {
        display:inline-block;
        position:relative;
        border-bottom:1px dotted #666;
        text-align:left;

        position: absolute;
        display: block;
        width: 22px;
        height: 22px;
        border-radius: 20px;
        cursor: pointer;


        &:hover {
            background-color: gray;
        }

        .left, .right {
            min-width:150px; 
            max-width:400px;
            transform:translate(0, -50%);
            padding:10px 20px;
            color:#FFFFFF;
            background-color:#444444;
            font-weight:normal;
            font-size:16px;
            border-radius:8px;
            position:absolute;
            z-index:99999999;
            box-sizing:border-box;
            box-shadow:0 1px 8px rgba(0,0,0,0.5);
            visibility:hidden; opacity:0; transition:opacity 0.8s;

        }

        .text-content {
            h3 {
                padding-bottom: 0;
                margin-bottom: 5px;
                margin-top: 5px;
                font-size: 20px;

                + span {
                    font-family: 'Lora', serif;
                    font-weight: 600;
                    color: #e9e9e9;
                    font-size: 14px;
                    margin-top: -5px;
                    margin-bottom: 10px;
                    display: block;
                }
            }

            ul {
                margin: 0 0 10px;
                padding: 0;
                display: block;
            }

            li {
                margin-left: 25px;
                white-space: nowrap;

                + li {
                    margin-top: 5px;
                }
            }
        }
    }

    .tooltip .left {
        top:50%;
        right:100%;
        margin-right:20px;
    }

    .tooltip:hover .left {
        visibility:visible; opacity:1;
    }

    .tooltip .left i {
        position:absolute;
        top:50%;
        left:100%;
        margin-top:-12px;
        width:12px;
        height:24px;
        overflow:hidden;
    }

    .tooltip .left i::after {
        content:'';
        position:absolute;
        width:12px;
        height:12px;
        left:0;
        top:50%;
        transform:translate(-50%,-50%) rotate(-45deg);
        background-color:#444444;
        box-shadow:0 1px 8px rgba(0,0,0,0.5);
    }

    .tooltip .right {
        top:50%;
        left:100%;
        margin-left:20px;
    }

    .tooltip:hover .right {
        visibility:visible; opacity:1;
    }

    .tooltip .right i {
        position:absolute;
        top:50%;
        right:100%;
        margin-top:-12px;
        width:12px;
        height:24px;
        overflow:hidden;
    }

    .tooltip .right i::after {
        content:'';
        position:absolute;
        width:12px;
        height:12px;
        left:0;
        top:50%;
        transform:translate(50%,-50%) rotate(-45deg);
        background-color:#444444;
        box-shadow:0 1px 8px rgba(0,0,0,0.5);
    }


}

.mainline {
	position: absolute;
	left: calc(281px * 2);
    width: calc(43px * 2);
    height: calc(2248px * 2);
    top: -60px;
}

// right side

@keyframes pulse-black {
  0% {
    transform: scale(0.85);
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.6);
  }
  
  70% {
    transform: scale(1);
    box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
  }
  
  100% {
    transform: scale(0.85);
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  }
}

.kentstate {
	display: block;
	position: absolute;
	left: calc(336px * 2);
    top: 256px;

    h2 {
        position: absolute;
        left: 120px;
        top: 30px;
        width: 300px;
    }

    img {
    	width: auto;
    	height: auto;
    }

    .ksulogo {
        width: 200px;
        margin-left: 119px;
        position: absolute;
    }

    .tooltip {
        left: 123px;
        top: 148px;
    }

    .image {
	    width: calc(212px * 2);
	    height: calc(63px * 2);
    }

    .line {
	    width: calc(111px * 2);
	    height: calc(75px * 2);

	    // clip: rect(0px, 20px, 115px, 0px);

	    position: absolute;
		left: -74px;
    	top: 22px;

	}

    .line-container::after {
        @include pulse-dot(123px, 148px);
    }
}

.learning {
	display: block;
	position: absolute;
	left: calc(330px * 2);
    top: 709px;

    img {
    	width: auto;
    	height: auto;
    }

    .tooltip {
        left: 96px;
        top: 194px;
    }
    .line-container::after {
        @include pulse-dot(96px, 193px);
    }

    .image {
	    width: calc(225px * 2);
	    height: calc(105px * 2);
    }

    .line {
	    width: calc(90px * 2);
	    height: calc(73px * 2);

	    // clip: rect(0px, 20px, 115px, 0px);

	    position: absolute;
		left: -59px;
    	top: 71px;	

	}
}

.graffiti {
	display: block;
	position: absolute;
	left: calc(340px * 2);
    top: 1368px;

    .left {
        max-width: 677px !important;
    }

    img {
    	width: auto;
    	height: auto;
    }

    h2 {
        position: absolute;
        width: 250px;
        top: 20px;
        left: 142px;
    }

    .cyancdesign {
        position: absolute;
        left: 145px;
        top: 81px;
    }

    .tooltip {
        top: 223px;
        left: 111px;
    }
    .line-container::after {
        @include pulse-dot(111px, 223px);
    }

    .image {
	    width: calc(225px * 2);
	    height: calc(105px * 2);
    }

    .line {
	    width: calc(90px * 2);
    	height: calc(91px * 2);
	    position: absolute;
	    left: -40px;
    	top: 65px;

	}
}

.ripleys {
	display: block;
	position: absolute;
	left: calc(337px * 2);
    top: 1917px;

    img {
    	width: auto;
    	height: auto;
    }

    .left {
        max-width: 677px !important;
    }

    h2 {
        position: absolute;
        top: -4px;
        left: 43px;
        width: 210px;
    }

    .cyancdesign {
        position: absolute;
        top: 92px;
        left: 44px;
    }

    .tooltip {
        top: 149px;
        left: 117px;
    }
    .line-container::after {
        @include pulse-dot(116.5px, 148.5px);
    }

    .image {
	    width: calc(225px * 2);
	    height: calc(105px * 2);
    }

    .line {
	    width: calc(108px * 2);
	    height: calc(98px * 2);
	    position: absolute;
	    left: -75px;
	    top: 47px;
	}
}

.onosystwo {
	display: block;
	position: absolute;
	left: calc(379px * 2);
    top: 2608px;

    img {
    	width: auto;
    	height: auto;
    }

    .tooltip {
        top: 102px;
        left: 110px;
    }
    .line-container::after {
        @include pulse-dot(111px, 102.5px);
    }

    .image {
	    width: calc(125px * 2);
	    height: calc(38px * 2);
    }

    .line {
	    width: calc(125px * 2);
	    height: calc(67px * 2);
	    position: absolute;
	    left: -115px;
    top: -7px;
	}
}

.scoutone {
	display: block;
	position: absolute;
	left: calc(397px * 2);
    top: 3198px;

    img {
    	width: auto;
    	height: auto;
    }

    .tooltip {
        top: 137px;
        left: 83px;
    }
    .line-container::after {
        @include pulse-dot(82px, 137px);
    }


    .image {
	    width: calc(92px * 2);
	    height: calc(56px * 2);
    }

    .line {
	width: calc(154px * 2);
    height: calc(109px * 2);
    position: absolute;
    left: -200px;
    top: -56px;
	}
}

.workday {
	display: block;
	position: absolute;
	    left: calc(389px * 2);
    top: 3880px;

    img {
    	width: auto;
    	height: auto;
    }

    .tooltip {
        top: 101px;
        left: 96px;
    }
    .line-container::after {
        @include pulse-dot(95.5px, 100px);
    }

    .image {
	    width: calc(102px * 2);
	    height: calc(41px * 2);
    }

    .line {
	width: calc(132px * 2);
    height: calc(27px * 2);
    position: absolute;
    left: -144px;
    top: 71px;
	}
}

// left side

.optiem {
	display: block;
	position: absolute;
	left: calc(100px * 2);
    top: 519px;

    img {
    	width: auto;
    	height: auto;
    }

    .tooltip {
        left: 110px;
        top: 79px;
    }
    .line-container::after {
        @include pulse-dot(111px, 79px);
    }

    .image {
	    width: calc(108px * 2);
	    height: calc(23px * 2);
    }

    .line {
	    width: calc(130px * 2);
	    height: calc(65px * 2);

	    // clip: rect(0px, 20px, 115px, 0px);

	    position: absolute;
		left: 108px;
    	top: -27px;

	}
}


.nuigroup {
	display: block;
	position: absolute;
	    left: calc(67px * 2);
    top: 1059px;

    img {
    	width: auto;
    	height: auto;
    }
    
    .right {
        max-width: 677px !important;
    }

    .cyancdesign {
        position: absolute;
        top: 129px;
        left: 183px;
    }

    .tooltip {
        left: 177px;
        top: 206px;
    }
    .line-container::after {
        @include pulse-dot(177px, 206px);
    }

    .image {
	    width: calc(173px * 2);
	    height: calc(86px * 2);
    }

    .line {
	    width: calc(128px * 2);
	    height: calc(103px * 2);

	    // clip: rect(0px, 20px, 115px, 0px);

	    position: absolute;
		left: 175px;
    top: 24px;

	}
}


.onosysone {
	display: block;
	position: absolute;
	left: calc(92px * 2);
    top: 1696px;

    img {
    	width: auto;
    	height: auto;
    }

    .tooltip {
        top: 96px;
        left: 111px;
    }
    .line-container::after {
        @include pulse-dot(110px, 96px);
    }

    .image {
	    width: calc(125px * 2);
	    height: calc(33px * 2);
    }

    .line {
	    width: calc(156px * 2);
    height: calc(69px * 2);
    position: absolute;
    left: 106px;
    top: -18px;

	}
}


.museum {
	display: block;
	position: absolute;
	left: calc(58px * 2);
    top: 2251px;
    
    .right {
        max-width: 677px !important;
    }

    img {
    	width: auto;
    	height: auto;
        position: absolute;
        top: -20px;
        left: -90px;
    }

    h2 {
        width: 210px;
        margin-bottom: 5px;
        position: absolute;
        top: 190px;
    }

    .cyancdesign {
        position: absolute;
        top: 320px;
    }

    .tooltip {
        top: 243px;
        left: 186px;
    }
    .line-container::after {
        @include pulse-dot(186px, 242px);
    }

    .image {
	    width: calc(193px * 2);
	    height: calc(107px * 2);
    }

    .line {
	    width: calc(137px * 2);
    height: calc(48px * 2);
    position: absolute;
    left: 181px;
    top: 170px;

	}
}



.health {
	display: block;
	position: absolute;
	left: calc(69px * 2);
    top: 2840px;

    img {
    	width: auto;
    	height: auto;
    }

    h2 {
        width: 240px;
        margin-bottom: 5px;
        position: absolute;
        top: 15px;
        left: 145px;
    }

    .cyancdesign {
        position: absolute;
        top: 145px;
        left: 145px;
    }

    .tooltip {
        top: 206px;
        left: 148px;
    }
    .line-container::after {
        @include pulse-dot(148px, 206px);
    }

    .image {
	    width: calc(187px * 2);
	    height: calc(87px * 2);
    }

    .line {
	    width: calc(158px * 2);
        height: calc(37px * 2);
        position: absolute;
        left: 145px;
        top: 157px;

	}
}



.scouttwo {
	display: block;
	position: absolute;
	    left: calc(93px * 2);
    top: 3471px;

    img {
    	width: auto;
    	height: auto;
    }

    .tooltip {
        top: 109px;
        left: 89px;
    }
    .line-container::after {
        @include pulse-dot(89px, 109px);
    }

    .image {
	    width: calc(123px * 2);
	    height: calc(38px * 2);
    }

    .line {
	       width: calc(147px * 2);
    height: calc(56px * 2);
    position: absolute;
    left: 85px;
    top: 21px;

	}
}

